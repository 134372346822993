import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import QntSwitch from '../../microComponents/qntSwitch/index';

const CartEditModal = ({ item, show, setShow }) => {
  const handleClose = () => {
    setShow(false);
    // setItem(item);
  };

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
      <Modal.Header closeButton>
        <div className='modTitle'>{item.name}</div>
      </Modal.Header>
      <Modal.Body>
        <div className='adderWrapper'>
          <section className='adderItems'>
            <div className='adderItems-name'>{item.name}</div>
            <QntSwitch
              item={{ product: item.product, name: ` ${item.name}` }}
            />
          </section>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CartEditModal;
