import React, { useContext } from 'react';
import TopBarContext from '../../Api/Context/topbarContext';
import ListSkeleton from '../../microComponents/ListSkeleton';

import { Link, animateScroll as scroll } from 'react-scroll';
const Index = ({ super_sub_category, loading }) => {
  const { clickSlider } = useContext(TopBarContext);

  return (
    <>
      <section className='SliderHolder'>
        {super_sub_category && super_sub_category.length > 0 ? (
          super_sub_category.length === 1 ? (
            <p></p>
          ) : (
            super_sub_category.map((sub, index) => (
              <article key={index} className='SliderHolder-items'>
                <figure
                  article
                  className='SliderHolder-items-image'
                  onClick={(e) => clickSlider()}
                >
                  <Link
                    to={`${sub.super_sub_category}`}
                    spy={true}
                    smooth={true}
                    duration={100}
                    offset={-170}
                  >
                    <img
                      src={`https://pokhara.tunahms.com/${sub.image}`}
                      alt=''
                    />
                  </Link>
                  <figcaption>{sub.super_sub_category}</figcaption>
                </figure>
              </article>
            ))
          )
        ) : (
          <p>
            <ListSkeleton />{' '}
          </p>
        )}
      </section>
    </>
  );
};

export default Index;
