import Cookies from 'js-cookie';
import React, { useContext, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import ComboContext from '../../Api/Context/comboContext';
import DefaultImage from '../../assets/images/default_offer.png';
import Spinner from 'react-bootstrap/Spinner';

const Index = () => {
  const { getCombos, combos, loading, error } = useContext(ComboContext);
  useEffect(() => {
    getCombos(Cookies.get('id'));
  }, []);
  return !error ? (
    <div>
      <section className='comboWrapper'>
        {!loading ? (
          combos.length > 0 ? (
            combos.map((combo) => (
              <div key={combo._id}>
                {/* <h1 className='comboWrapper-title'>{combo.title}</h1> */}

                <Carousel>
                  {combo.meals.map((meal) => (
                    <Carousel.Item key={meal._id}>
                      <img
                        className='comboWrapper-image'
                        src={`${process.env.REACT_APP_IMAGE_URL}/uploads/${meal.image}`}
                        alt='First slide'
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            ))
          ) : (
            <div>
              <img src={DefaultImage} alt='' className='comboWrapper-image' />
            </div>
          )
        ) : (
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <Spinner
              animation='grow'
              variant='secondary'
              style={{ marginTop: '25%' }}
            />
            <div className='text-secondary'>Loading</div>
          </div>
        )}
      </section>
    </div>
  ) : (
    <h1>{error}</h1>
  );
};

export default Index;
